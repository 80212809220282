<template>
  <div class="preloader"></div>
  <header class="header">
    <!-- <TopHeader v-if="checkVisibility(top, 'MenuBar')" :blok="top" /> -->
    <TopHeader :blok="top" />
    <TopBar v-if="menu" :blok="menu" />
  </header>

  <AboutApp v-if="about" :blok="about" />
  <OurFeatures v-if="features" :blok="features" />
  <DownloadFree v-if="dfree" :blok="dfree" />
  <Screenshots v-if="screens" :blok="screens" />
  <Statistics v-if="stats" :blok="stats" />
  <DownloadAppFree v-if="dafree" :blok="dafree" />
  <FooterApp v-if="foot" :blok="foot" />
</template>

<script setup>
import { useStoryblokApi } from "@storyblok/vue";
// Components
import AboutApp from '@/components/aboutApp.vue';
import DownloadAppFree from '@/components/downloadAppFree.vue';
import DownloadFree from '@/components/downloadFree.vue';
import FooterApp from '@/components/footer.vue';
import TopHeader from '@/components/header.vue';
import TopBar from '@/components/menuBar.vue';
import OurFeatures from '@/components/ourFeatures.vue';
import Screenshots from '@/components/screenshots.vue';
import Statistics from '@/components/statistics.vue';

function checkVisibility(data, type) {
  const { component } = data
  return data && component === type
}

const storyblokApi = useStoryblokApi();
const { data } = await storyblokApi.get("cdn/stories/home", { version: "published" });
const { body } = data.story.content
const [top, menu, about, features, dfree, screens, stats, dafree, foot] = body
// console.log('storyblokApi', top)
</script>
