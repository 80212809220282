<template>
  <Suspense>
    <router-view />
  </Suspense>
</template>

<style lang="scss">
#app {
  height: 100vh;
}
</style>
