/* eslint-disable */
import { StoryblokVue, apiPlugin } from '@storyblok/vue';
import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';

createApp(App)
  .use(router)
  .use(StoryblokVue, {
    accessToken: process.env.VUE_APP_STORYBLOK_APIKEY,
    apiOptions: {
      region: process.env.VUE_APP_STORYBLOK_REGION,
      cache: { type: 'memory' },
    },
    use: [apiPlugin],
  })
  .mount('#app');
